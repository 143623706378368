import React from 'react';

const PassFailButtons = ({ graded, handlePass, handleFail, handleEditGradeButtonClick }) => {
    return (
        <div className='passFailBtns'>
            {!graded ? (
                <div>
                    <button onClick={handlePass}>Pass &#x2713;</button>
                    <button onClick={handleFail}>Fail &#x2715;</button>
                </div>
            ) : (
                <button onClick={handleEditGradeButtonClick}>Edit Grade</button>
            )}
        </div>
    );
};

export default PassFailButtons;
