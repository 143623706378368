import React, { useState, useEffect } from 'react';
import StudentBanner from '../components/student/studentBanner';
import StudentNextLesson from '../components/student/studentNextLesson';
import ParkImage from '../images/streetWithBenchAndLights.jpg';
import WelcomeBot from '../images/BabbleHandsUp.png';
import { useLocation } from 'react-router-dom';
import { firestore } from '../firebase.js';
import { doc, getDoc, getDocs, collection, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons';

import '../css/studentDash.css';

library.add( faBookOpenReader );

export default function StudentDash() {
    const location = useLocation();
    const teacherDocumentID = location.state.teacherID;
    const [curriculumName, setCurriculumName] = useState("");
    const studentDocumentID = location.state.studentID;
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState(""); // State to store the user's first name
    const [studentCurrentObjectiveNumber, setStudentCurrentObjectiveNumber] = useState(""); // State to store the student's current objective
    const [currentObjectiveEnglishTranslations, setCurrentObjectiveEnglishTranslations] = useState(''); 
    const [currentObjectivePhonemicTranslations, setCurrentObjectivePhonemicTranslations] = useState('');
    const [allEnglishTranslations, setAllEnglishTranslations] = useState('')
    const [allPhonemicTranslations, setallPhonemicTranslations] = useState('')
    const [passedLessons, setPassedLessons] = useState('');
    const [previousScore, setPreviousScore] = useState(null);
    const [lastLessonName, setLastLessonName] = useState('');
    const [totalLessons, setTotalLessons] = useState(null);
    const [passCount, setPassCount] = useState();

    const [cachedData, setCachedData] = useState({
        englishTranslations: [],
        phonemicTranslations: [],
    });

    useEffect(() => {
        const unsubscribeFunctions = [];

        const fetchFirstName = async () => {
            try {
                const studentDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID);
                const studentDocSnapshot = await getDoc(studentDocRef);

                const unsubscribe = onSnapshot(studentDocRef, async (studentDocSnapshot) => {
                    if (studentDocSnapshot.exists()) {
                        const userData = studentDocSnapshot.data();
                        setFirstName(userData.firstName);
                        setStudentCurrentObjectiveNumber(userData.currentObjective);
                    }
                });

                unsubscribeFunctions.push(unsubscribe);
            } catch (error) {
                console.error("Error fetching first name: ", error.message);
            }
        };

        fetchFirstName();

        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID]);

    useEffect(() => {
        const fetchCurriculumName = async () => {
            try {
                const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
                const teacherDocSnapshot = await getDoc(teacherDocRef);
                if (teacherDocSnapshot.exists()) {
                    const teacherData = teacherDocSnapshot.data();
                    setCurriculumName(teacherData.curriculumName);
                    console.log(curriculumName);
                }
            } catch (error) {
                console.error('Error fetching curriculum name:', error);
            }
        };

        fetchCurriculumName();
    }, [teacherDocumentID]);

    useEffect(() => {
        const fetchObjectiveData = async () => {
            if (!studentCurrentObjectiveNumber || !curriculumName) return;

            try {
                const currentObjectiveDataRef = doc(firestore, 'curriculums', curriculumName, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const currentObjectiveDataSnapshot = await getDoc(currentObjectiveDataRef);
                const currentObjectiveData = currentObjectiveDataSnapshot.data();

                if (currentObjectiveData) {
                    const phonemicTranslations = currentObjectiveData.phonemicStructures.map(structure => structure.phonemicTranslation);
                    const phonemicTranslationsString = phonemicTranslations.join(', ').toUpperCase() || 'n/a';

                    const englishTranslations = currentObjectiveData.phonemicStructures.map(structure => structure.englishTranslation);
                    const englishTranslationsString = englishTranslations.join(', ') || 'n/a';

                    setCurrentObjectivePhonemicTranslations(phonemicTranslationsString);
                    setCurrentObjectiveEnglishTranslations(englishTranslationsString);
                } else {
                    setCurrentObjectivePhonemicTranslations('n/a');
                    setCurrentObjectiveEnglishTranslations('n/a');
                }
            } catch (error) {
                console.error('Error fetching objective data:', error);
            }
        };

        fetchObjectiveData();
    }, [studentCurrentObjectiveNumber, curriculumName]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const previousLessonsData = () => {
            try {
                const previousLessonRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives');
                
                const unsubscribe = onSnapshot(previousLessonRef, async (previousLessonSnapshot) => {
                    if (!previousLessonSnapshot.empty) {
                        const lessonDocs = previousLessonSnapshot.docs;
                        const previousLessonDoc = lessonDocs[lessonDocs.length - 1];
                        const previousLessonDocRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `${previousLessonDoc.id}`, 'lessons');
                        
                        // onSnapshot to listen for real-time updates within that collection.
                        const unsubscribeNested = onSnapshot(previousLessonDocRef, (nestedSnapshot) => {
                            let passCount = 0; // Initialize a counter for 'pass' fields equal to true
                            nestedSnapshot.docs.forEach(doc => {
                                const lessonData = doc.data();
                                if (lessonData.pass === true) { // Check if 'pass' field is true
                                    passCount += 1; // Increment count if true
                                }
                            });
                            
                            setPassCount(passCount); // Update state with the latest count
                        });
                        console.log(lessonDocs)
                        unsubscribeFunctions.push(unsubscribeNested);
                    }
                });
        
                // Assuming you have an array unsubscribeFunctions where you store unsubscribe callbacks
                unsubscribeFunctions.push(unsubscribe);
                console.log(passCount);
        
            } catch (error) {
                console.log(error);
            }
        };

        previousLessonsData();

        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID]);

    useEffect(() => {
        const loadCachedData = () => {
            const englishTranslations = sessionStorage.getItem('englishTranslations');
            const phonemicTranslations = sessionStorage.getItem('phonemicTranslations');
            if (englishTranslations && phonemicTranslations) {
                setCachedData({
                    englishTranslations: JSON.parse(englishTranslations),
                    phonemicTranslations: JSON.parse(phonemicTranslations),
                });
                setIsLoading(false);
                console.log(cachedData)
                return true;
            }
            console.log('did not load cached data');
            return false;
        };

        const englishTranslationsAndPhonemicTranslations = async () => {
            if (loadCachedData()) {
                return;
            }
            if (!studentCurrentObjectiveNumber) return;

            try {
                const fetchedObjectiveData = [];
                const allPhonemicTranslations = [];
                const allEnglishTranslations = [];

                // Fetch objectives including and preceding the selected objective
                for (let i = 1; i <= studentCurrentObjectiveNumber; i++) {
                    const objectiveDocRef = doc(firestore, 'curriculums', `${curriculumName}`, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                    const objectiveDocSnap = await getDoc(objectiveDocRef);
                    if (objectiveDocSnap.exists()) {
                        const data = objectiveDocSnap.data();
                        fetchedObjectiveData.push(data);
                        console.log('read from firebase')
                        setIsLoading(false);
                    }
                }

                if (fetchedObjectiveData.length > 0) {
                    fetchedObjectiveData.forEach(objectiveData => {
                        if (objectiveData.englishTranslations) {
                            allEnglishTranslations.push(...objectiveData.englishTranslations);
                        }
                        if (objectiveData.phonemicTranslations) {
                            allPhonemicTranslations.push(...objectiveData.phonemicTranslations);
                        }
                    });

                    // Use Set to remove duplicates and then update state
                    setCachedData({
                        englishTranslations: [...new Set(allEnglishTranslations)],
                        phonemicTranslations: [...new Set(allPhonemicTranslations)],
                    });

                    console.log(cachedData);

                    // Cache the data for future use
                    sessionStorage.setItem('englishTranslations', JSON.stringify([...new Set(allEnglishTranslations)]));
                    sessionStorage.setItem('phonemicTranslations', JSON.stringify([...new Set(allPhonemicTranslations)]));
                } else {
                    console.log("No objectives found!");
                    setCachedData({ englishTranslations: [], phonemicTranslations: [] });
                }
            } catch (error) {
                console.error("Error fetching objectives data:", error);
                setCachedData({ englishTranslations: [], phonemicTranslations: [] });
            }
        };

        englishTranslationsAndPhonemicTranslations();
    }, [studentCurrentObjectiveNumber]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const updateTotalLessons = async () => {
            if (!studentCurrentObjectiveNumber) return;

            try {
                const objectiveDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const lessonsCollectionRef = collection(objectiveDocRef, 'lessons');
                const lessonsSnapshot = await getDocs(lessonsCollectionRef);
                const totalLessonsCount = lessonsSnapshot.size;

                await updateDoc(objectiveDocRef, { totalLessons: totalLessonsCount });
                setTotalLessons(totalLessonsCount); // Update local state with total lessons count
            } catch (error) {
                console.error("Error updating total lessons count:", error.message);
            }
        };

        updateTotalLessons();

        // Subscribe to changes in lessons collection for real-time updates
        const lessonsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`, 'lessons');
        const unsubscribeLessonSnapshot = onSnapshot(lessonsCollectionRef, updateTotalLessons);
        unsubscribeFunctions.push(unsubscribeLessonSnapshot);

        return () => {
            // Unsubscribe from snapshot listeners when component unmounts
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID, studentCurrentObjectiveNumber]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const updatePassedLessons = async () => {
            if (!studentCurrentObjectiveNumber) return;

            try {
                const objectiveDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const lessonsCollectionRef = collection(objectiveDocRef, 'lessons');
                const lessonsSnapshot = await getDocs(lessonsCollectionRef);
                let passedLessonsCount = 0;

                // Count the number of lessons with 'pass' field set to true
                lessonsSnapshot.forEach(lessonDoc => {
                    const lessonData = lessonDoc.data();
                    if (lessonData.pass === true) {
                        passedLessonsCount++;
                    }
                });

                // Update the 'passedLessons' field in the objective document
                await updateDoc(objectiveDocRef, { passedLessons: passedLessonsCount });
                setPassedLessons(passedLessonsCount); // Update local state with passed lessons count
            } catch (error) {
                console.error("Error updating passed lessons count:", error.message);
            }
        };

        updatePassedLessons();

        // Subscribe to changes in lessons collection for real-time updates
        const lessonsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`, 'lessons');
        const unsubscribeLessonSnapshot = onSnapshot(lessonsCollectionRef, updatePassedLessons);
        unsubscribeFunctions.push(unsubscribeLessonSnapshot);

        return () => {
            // Unsubscribe from snapshot listeners when component unmounts
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID, studentCurrentObjectiveNumber]);

    return (
        <div className='studentDashPage' style={{backgroundImage: `url(${ParkImage})`}}>
            {/* Pass the fetched first name to the StudentBanner component 
            <StudentBanner studentName={firstName} />*/}
            
            {isLoading ? ( 
                <div className='loadingContainer' style={{display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '300px'}}>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            ) : (
                <div className='studentDash'>
                    <div className='studentDashLeft'>
                        <img src={WelcomeBot} />
                        <p className='speechBubble'>Hello, {firstName}!</p>
                    </div>
                    <div className='studentDashRight'>
                        {/* <div className='currentLesson'>
                            <div className='lessonDetails' style={{display: 'flex'}}>
                                <FontAwesomeIcon className='detailIcon' icon="fa-solid fa-book-open-reader" />
                                <div>
                                    <p>Unit {studentCurrentObjectiveNumber}</p>
                                    <p>Sounds: {currentObjectiveEnglishTranslations}</p>
                                </div>
                            </div>
                            <button>Start!</button>
                        </div> */}
                        <StudentNextLesson 
                            previousLesson={lastLessonName}
                            previousLessonScore={previousScore}
                            previousLessonAudio=''
                            scoreColor=''
                            teacherDocumentID={teacherDocumentID}
                            studentDocumentID={studentDocumentID}
                            studentCurrentObjectiveNumber={studentCurrentObjectiveNumber}
                            currentObjectiveEnglishTranslations={currentObjectiveEnglishTranslations}
                            currentObjectivePhonemicTranslations={currentObjectivePhonemicTranslations}
                            passedLessons={passCount}
                            totalLessons={totalLessons}
                            allEnglishTranslations={allEnglishTranslations}
                            allPhonemicTranslations={allPhonemicTranslations}
                            //HAD ALLPHONEMES AND ALLSIGHTWORDS PASSED
                            cachedData={cachedData}
                        />

                    </div>
                </div>
            )}
            
        </div>
    );
}
