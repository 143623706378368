import React from 'react';
import './ourGoal.css';


export default function OurGoal() {
    return (
        <div className='goalContainer'>
            <h2>Our Goal</h2>
            <p>BabbleBot's mission is to create a personal, engaging and thoughtful learning 
                experience for students. Along with streamlining the analysis of student learning 
                for teachers with the help of the latest AI technology.</p>
        </div>
    )
}