import React from 'react';
import './subBanner.css';

export default function SubBanner() {
    return (
        <div className='subBannerContainer'>
            <p className='subBannerText'>
                <b>Try it out! </b>
                Generate a short decodable for your students in 
                <b> seconds!</b>
            </p>
        </div>
    )
}