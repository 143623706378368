import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faCat, faBug } from '@fortawesome/free-solid-svg-icons';

import '../../css/componentStyles.css';

library.add( faDog, faCat, faBug );

export default function CharacterSelect({ onSelectCharacter }){
    // Function to handle button clicks
    const handleButtonClick = (event) => {
        onSelectCharacter(event.target.value);
    };

    return (
        <>
        <div className='characterSelect' id='characterSelect'>
            <button className='characterBtn' value='dog' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-dog"/>
                Dog
            </button>
            <button className='characterBtn' value='cat' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-cat" />
                Cat
            </button>
            <button className='characterBtn' value='bug' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-bug" />
                Bug
            </button>
        </div>
        </>
    );
}
