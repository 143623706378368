import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";
import { collection, doc, getDocs, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './modifyCurriculum.css';

const ItemTypes = {
  PHONEMIC_STRUCTURE: 'phonemicStructure',
};

const DraggableStructure = ({ id, phonemicTranslation, englishTranslation }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.PHONEMIC_STRUCTURE,
    item: { id, phonemicTranslation, englishTranslation },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [id, phonemicTranslation, englishTranslation]);

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {phonemicTranslation}, {englishTranslation}
    </div>
  );
};

const DroppableObjective = ({ objectiveNumber, curriculumData, onDrop, onEdit, items }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.PHONEMIC_STRUCTURE,
    drop: (item) => onDrop(objectiveNumber, item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [objectiveNumber, onDrop]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    onEdit();
  };

  return (
    <div ref={drop} className='objectiveBox'>
      <div className='objectiveHeader'>
        <p>Objective {objectiveNumber}</p>
        {!isEditing ? (
          <button onClick={handleEditClick}>Edit</button>
        ) : (
          <button onClick={handleSaveClick}>Save</button>
        )}
      </div>
      {!isEditing && items && (
        <div className='droppedPhonemes'>
          {items.map((item, index) => (
            <div key={index}>{item.phonemicTranslation}, {item.englishTranslation}</div>
          ))}
        </div>
      )}
      {isEditing && (
        <div className='draggablesContainer'>
          {Object.entries(curriculumData.structureTypes).map(([type, structures]) => (
            <div className={type} key={type}>
              <p>{type}</p>
              <section className='blockContainer'>
                {structures.map(structure => (
                  <DraggableStructure className='draggable' key={structure.id} {...structure} />
                ))}
              </section>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default function ModifyCurriculum({ teacherDocumentID }) {
  const [objectives, setObjectives] = useState(0);
  const [structureTypes, setStructureTypes] = useState({});
  const [curriculum, setCurriculum] = useState([]);
  const [existingCurriculum, setExistingCurriculum] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPhonemicStructures = async () => {
      const structureTypes = ["consonantblends", "vowels", "consonants"];
      let fetchedStructures = {};

      for (const type of structureTypes) {
        const structuresCollectionRef = collection(firestore, 'phonemicStructures', type, 'structures');
        const structuresSnapshot = await getDocs(structuresCollectionRef);
        fetchedStructures[type] = structuresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }

      setStructureTypes(fetchedStructures);
    };

    fetchPhonemicStructures().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const fetchCurriculum = async () => {
      const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
      const teacherDocSnap = await getDoc(teacherDocRef);

      if (teacherDocSnap.exists()) {
        const teacherData = teacherDocSnap.data();
        if (teacherData.curriculumName) {
          // Fetch existing curriculum
          const curriculumDocRef = doc(firestore, 'curriculums', teacherData.curriculumName);
          const curriculumDocSnap = await getDoc(curriculumDocRef);

          if (curriculumDocSnap.exists()) {
            const curriculumData = curriculumDocSnap.data();
            setObjectives(curriculumData.numberOfObjectives);
            const curriculumObjectives = [];

            for (let i = 1; i <= curriculumData.numberOfObjectives; i++) {
              const objectiveDocRef = doc(curriculumDocRef, 'objectives', `objective${i}`);
              const objectiveSnap = await getDoc(objectiveDocRef);
              if (objectiveSnap.exists()) {
                const { phonemicStructures } = objectiveSnap.data();
                const items = phonemicStructures.map(({ phonemicTranslation, englishTranslation }) => ({
                  phonemicTranslation,
                  englishTranslation,
                }));
                curriculumObjectives.push(items);
              }
            }
            setCurriculum(curriculumObjectives);
            setExistingCurriculum(curriculumData);
          }
        }

        if (teacherData.gradeLevel) {
          setGradeLevel(teacherData.gradeLevel);
        }
      }
    };

    fetchCurriculum().then(() => setIsLoading(false));
  }, [teacherDocumentID]);

  const handleObjectiveNumberChange = (e) => {
    const objectiveCount = Math.min(Math.max(parseInt(e.target.value), 1), 60);
    setObjectives(objectiveCount);
    setCurriculum(Array.from({ length: objectiveCount }, () => []));
  };

  const handleDrop = (objectiveNumber, item) => {
    setCurriculum(curriculum => {
      const newCurriculum = [...curriculum];
      newCurriculum[objectiveNumber - 1] = [...newCurriculum[objectiveNumber - 1], item];
      return newCurriculum;
    });
  };

  const handleEditObjective = async (objectiveNumber) => {
    try {
      const objectiveDocRef = doc(firestore, 'curriculums', teacherDocumentID, 'objectives', `objective${objectiveNumber}`);
      const objectiveItems = curriculum[objectiveNumber - 1];
      const phonemicStructures = objectiveItems.map(item => ({
        phonemicTranslation: item.phonemicTranslation,
        englishTranslation: item.englishTranslation,
      }));

      await setDoc(objectiveDocRef, {
        objectiveNumber,
        phonemicStructures,
      });

      alert(`Objective ${objectiveNumber} updated successfully!`);
    } catch (error) {
      console.error(`Error updating objective ${objectiveNumber}:`, error);
      alert(`Error updating objective ${objectiveNumber}. Please try again.`);
    }
  };

  const handleSubmit = async () => {
    try {
      const curriculumData = {
        numberOfObjectives: objectives,
      };

      if (gradeLevel) {
        curriculumData.gradeLevel = gradeLevel;
      }

      if (!existingCurriculum) {
        // Create new curriculum
        const curriculumDocRef = doc(firestore, 'curriculums', teacherDocumentID);
        await setDoc(curriculumDocRef, curriculumData);

        // Update curriculumName in teacherDocument
        const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
        await updateDoc(teacherDocRef, {
          curriculumName: teacherDocumentID
        });
      }

      // Update or create objectives
      for (let i = 0; i < objectives; i++) {
        const objectiveDocRef = doc(firestore, 'curriculums', teacherDocumentID, 'objectives', `objective${i + 1}`);
        const objectiveItems = curriculum[i];
        const phonemicStructures = objectiveItems.map(item => ({
          phonemicTranslation: item.phonemicTranslation,
          englishTranslation: item.englishTranslation,
        }));

        await setDoc(objectiveDocRef, {
          objectiveNumber: i + 1,
          phonemicStructures,
        });
      }

      alert('Curriculum updated successfully!');
    } catch (error) {
      console.error('Error updating curriculum:', error);
      alert('Error updating curriculum. Please try again.');
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <p style={{ fontSize: '25px', margin: '0 0 10px' }}>{existingCurriculum ? 'Your Classroom Curriculum' : 'Create your Own'}</p>
      <div className='headerInfo'>
        <span>
          <label htmlFor="objectives">Number of Objectives (Ex: 16): {objectives} </label>
        </span>
        <button onClick={handleSubmit}>{existingCurriculum ? 'Save' : 'Create and Assign'}</button>
      </div>

      <div className='curriculumCreatorContainer'>
        <div className='boxesContainer'>
          <div>
            {Array.from({ length: objectives }, (_, i) => (
              <DroppableObjective
                key={i + 1}
                objectiveNumber={i + 1}
                curriculumData={{ structureTypes }}
                onDrop={handleDrop}
                onEdit={() => handleEditObjective(i + 1)}
                items={curriculum[i]}
              />
            ))}
          </div>
        </div>

        <div className='draggablesContainer'>
          {Object.entries(structureTypes).map(([type, structures]) => (
            <div className={type} key={type}>
              <p>{type}</p>
              <section className='blockContainer'>
                {structures.map(structure => (
                  <DraggableStructure className='draggable' key={structure.id} {...structure} />
                ))}
              </section>
            </div>
          ))}
        </div>
      </div>
    </DndProvider>
  );
}