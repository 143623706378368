// LessonDetailsDisplay.jsx
import React from 'react';

const LessonDetailsDisplay = ({ 
    lesson,
    graded
}) => {
    const passValue = lesson.pass;

    return (
        <div className="lessonDetailsDisplay">
            <div className='dataInnerContainer generatedStory'>
                <h3>Generated Story:</h3>
                <p>{lesson.storyContent || ''}</p>
            </div>

            <div className='dataInnerContainer transcript'>
                <h3>Transcript:</h3>
                <p>{lesson.transcript || ''}</p>
            </div>

            <div className='dataInnerContainer score'>
                <h3>Score:</h3>
                <p>{lesson.score || ''}</p>
                <h3>Teacher Grade:</h3>
                <p>{graded ? (passValue !== null ? (passValue ? 'Pass' : 'Fail') : 'Not Graded') : 'Not Graded'}</p>
            </div>

            <div className='dataInnerContainer audio'>
                <h3>Audio:</h3>
                <audio src={lesson.audio || ''} controls />
            </div>
        </div>
    );
};

export default LessonDetailsDisplay;
