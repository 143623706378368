import React, { useState } from 'react';
import { collection, addDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";

const AddStudentComponent = ({ 
    teacherDocumentID,
    setStudentsData
    }) => {
    const [showInputs, setShowInputs] = useState(false); // State to manage visibility of inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [currentObjective, setCurrentObjective] = useState('');

    const handleAddButtonClick = () => {
        setShowInputs(true);
    };

    const handleConfirmButtonClick = async () => {
        if (!firstName || !lastName || !currentObjective) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            const studentsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students');
            await addDoc(studentsCollectionRef, {
                firstName,
                lastName,
                currentObjective: parseInt(currentObjective) // Convert setCurrentObjective to a number
            });
            // Clear input fields after adding student
            setFirstName('');
            setLastName('');
            setCurrentObjective('');
            setShowInputs(false); // Hide inputs after adding student
                        const studentsSnapshot = await getDocs(studentsCollectionRef);
                        const studentsData = studentsSnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }));
                        
                        if(studentsData) {
                        if (studentsData.length >= 1) { 
                                const sortedStudents = studentsData.sort((a, b) => a.firstName.localeCompare(b.firstName));
                                setStudentsData(sortedStudents);
                        }}
        } catch (error) {
            console.error('Error adding student:', error);
            alert('Error adding student. Please try again.');
        }
    };

    return (
        <div>
            <button onClick={handleAddButtonClick}>+ Add Student</button>
            {showInputs && (
                <div>
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Current Objective"
                        value={currentObjective}
                        onChange={(e) => setCurrentObjective(e.target.value)}
                    />
                    <button onClick={handleConfirmButtonClick}>Confirm!</button>
                </div>
            )}
        </div>
    );
};

export default AddStudentComponent;
