import React, { useState, useEffect } from 'react';
import { firestore } from "../../firebase";
import { collection, getDocs, updateDoc, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import './teacher.css';

import StudentProgressChart from './StudentProgressChart.jsx';
import AddStudent from './addStudent.jsx';
import StudentPopup from './StudentPopup.jsx';


const TeacherFetch = ({
    teacherDocumentID = '',
}) => {
    const [studentsData, setStudentsData] = useState(null);
    const [curriculumName, setCurriculumName] = useState(null);
    const [objectivesData, setObjectivesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [lessonObjectiveData, setLessonObjectiveData] = useState(null);
    const [graded, setGraded] = useState(false); // Track graded state
    const [passValue, setPassValue] = useState(null); // Track pass field value
    const [currentObjective, setCurrentObjective] = useState(null); // Add currentObjective state
    const [lessonsData, setLessonsData] = useState([]);
    const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [selectedLessonId, setSelectedLessonId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch curriculum name from teacher document
                const teacherDocRef = doc(firestore, 'users', `${teacherDocumentID}`);
                const teacherDocSnapshot = await getDoc(teacherDocRef);
        
                if (teacherDocSnapshot.exists()) {
                    const teacherData = teacherDocSnapshot.data();
                    const curriculumName = teacherData.curriculumName;
                    setCurriculumName(curriculumName);
                    console.log(curriculumName)
        
                    if (curriculumName) {
                        // Fetch students data
                        const studentsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students');
                        const studentsSnapshot = await getDocs(studentsCollectionRef);
                        const studentsData = studentsSnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }));
                        
                        if(studentsData) {
                        if (studentsData.length >= 1) { 
                                const sortedStudents = studentsData.sort((a, b) => a.firstName.localeCompare(b.firstName));
                                setStudentsData(sortedStudents);
                        }}
                        
                        // Calculate the highest current objective and update the teacher document
                        const highestCurrentObjective = Math.max(...studentsData.map(student => student.currentObjective || 0));
                        await updateDoc(teacherDocRef, { 
                            highestCurrentObjective: highestCurrentObjective
                        });

        
                        // Fetch objectives data
                        const objectivesCollectionRef = collection(firestore, 'curriculums', curriculumName, 'objectives');
                        const objectivesSnapshot = await getDocs(objectivesCollectionRef);
                        const objectivesData = objectivesSnapshot.docs.map(doc => doc.data());
                        const sortedObjectives = objectivesData.sort((a, b) => a.objectiveNumber - b.objectiveNumber);
                        setObjectivesData(sortedObjectives);
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error);
                setLoading(false);
            }
        };
        
        

        fetchData();
    }, [teacherDocumentID]);

    useEffect(() => {
        if (selectedStudent && selectedObjective !== null) {
            fetchLessonData(selectedObjective, selectedStudent.id);
        }
    }, [selectedStudent, selectedObjective]);

    useEffect(() => {
        // Fetch pass field value when graded state changes
        if (selectedStudent && selectedObjective !== null && selectedLessonId && graded) {
            fetchPassValue(selectedStudent.id, selectedObjective, selectedLessonId);
        }
    }, [graded]);

    useEffect(() => {
        if (selectedStudent) {
            const studentDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${selectedStudent.id}`);
            const unsubscribe = onSnapshot(studentDocRef, (doc) => {
                const studentData = doc.data();
                if (studentData) {
                    setCurrentObjective(studentData.currentObjective);
                }
            });
            return () => unsubscribe();
        }
    }, [selectedStudent]);
    

    const fetchStudentLessonData = async (student) => {
        try {
            // Fetch Student Lesson data
            const studentObjectiveCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${student}`, 'objectives');
            const objectiveLessonSnapshot = await getDocs(studentObjectiveCollectionRef);
            const objectiveDocsData = objectiveLessonSnapshot.docs.map(doc => doc.data()); // Renamed variable for clarity
            setLessonObjectiveData(objectiveDocsData);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }


    const fetchLessonData = async (objectiveNumber, student) => {
        try {
            const lessonsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${student}`, 'objectives', `objective${objectiveNumber}`, 'lessons');
            const lessonsSnapshot = await getDocs(lessonsCollectionRef);
            
            let lessonsData = lessonsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Sort lessonsData alphanumerically based on lesson ID
            lessonsData.sort((a, b) => {
                const lessonIdA = parseInt(a.id.replace('lesson', '')); // Extract the lesson number from the ID
                const lessonIdB = parseInt(b.id.replace('lesson', '')); // Extract the lesson number from the ID
                return lessonIdA - lessonIdB;
            });
    
            if (lessonsData.length > 0) {
                setLessonsData(lessonsData);
                setCurrentLessonIndex(0); // Reset current lesson index
                setSelectedLessonId(lessonsData[0].id); // Select the first lesson
                displayLesson(lessonsData[0]); // Display the first lesson
    
                // Fetch pass value for the displayed lesson
                fetchPassValue(selectedStudent.id, selectedObjective, lessonsData[0].id);
            } else {
                // No lessons found for the selected objective
                setLessonsData([]);
                setCurrentLessonIndex(0);
                setSelectedLessonId(null);
            }
        } catch (error) {
            console.error("Failed to fetch lesson data:", error);
        }
    };
    

    function displayLesson(lesson) {
        setGraded(lesson.isGraded || false); // Update graded state
    
        if (lesson.isGraded === false) {
            setPassValue(null); // Reset passValue if not graded
        } else if (lesson.hasOwnProperty('pass')) {
            setPassValue(lesson.pass); // Set passValue if it exists
        } else {
            setPassValue(null); // Set passValue to null if 'pass' field doesn't exist
        }
    
        setSelectedLessonId(lesson.id); // Update selected lesson ID
    }
    
    
    
    function nextLesson() {
        if (currentLessonIndex < lessonsData.length - 1) {
            setCurrentLessonIndex(prevIndex => prevIndex + 1); // Use callback version of setCurrentLessonIndex
            displayLesson(lessonsData[currentLessonIndex + 1]);
        }
    }
    
    function previousLesson() {
        if (currentLessonIndex > 0) {
            setCurrentLessonIndex(prevIndex => prevIndex - 1); // Use callback version of setCurrentLessonIndex
            displayLesson(lessonsData[currentLessonIndex - 1]);
        }
    }
    
    
    const handlePass = async (lessonId) => {
        if (selectedStudent && selectedObjective !== null && lessonId) {
            if (currentObjective < selectedStudent.currentObjective) return; // Prevent editing for previous objectives
    
            try {
                const lessonDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${selectedStudent.id}`, 'objectives', `objective${selectedObjective}`, 'lessons', lessonId);
                await updateDoc(lessonDocRef, {
                    pass: true,
                    isGraded: true
                });
    
                await recalculatePassedLessons(selectedStudent.id, selectedObjective);
    
                const updatedLessonsData = lessonsData.map(lesson => {
                    if (lesson.id === lessonId) {
                        return { ...lesson, pass: true, isGraded: true };
                    }
                    return lesson;
                });
                setLessonsData(updatedLessonsData);
                setGraded(true);
            } catch (error) {
                console.error("Error handling pass:", error);
            }
        }
    };
    
    const handleFail = async (lessonId) => {
        if (selectedStudent && selectedObjective !== null && lessonId) {
            if (selectedObjective < selectedStudent.currentObjective) return; // Prevent editing for previous objectives
    
            try {
                const lessonDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${selectedStudent.id}`, 'objectives', `objective${selectedObjective}`, 'lessons', lessonId);
                await updateDoc(lessonDocRef, {
                    pass: false,
                    isGraded: true
                });
    
                await recalculatePassedLessons(selectedStudent.id, selectedObjective);
    
                const updatedLessonsData = lessonsData.map(lesson => {
                    if (lesson.id === lessonId) {
                        return { ...lesson, pass: false, isGraded: true };
                    }
                    return lesson;
                });
                setLessonsData(updatedLessonsData);
                setGraded(true);
            } catch (error) {
                console.error("Error handling fail:", error);
            }
        }
    };
    
    
    const recalculatePassedLessons = async (studentDocumentID, objectiveNumber) => {
        try {
            const lessonsCollectionRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNumber}`, 'lessons');
            const lessonsSnapshot = await getDocs(lessonsCollectionRef);
            const passedLessonsCount = lessonsSnapshot.docs.filter(doc => doc.data().pass === true).length;
    
            const objectiveDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNumber}`);
            await updateDoc(objectiveDocRef, { passedLessons: passedLessonsCount });
    
            const studentDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`);
            const studentDocSnapshot = await getDoc(studentDocRef);
            const studentData = studentDocSnapshot.data();
    
            if (passedLessonsCount >= 4) {
                if (studentData.currentObjective === objectiveNumber) {
                    await updateDoc(studentDocRef, { currentObjective: studentData.currentObjective + 1 });
    
                    // Create a new document in the student's objectives subcollection
                    const newObjectiveNumber = studentData.currentObjective + 1;
                    const newObjectiveDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${newObjectiveNumber}`);
                    await setDoc(newObjectiveDocRef, {
                        objectiveNumber: newObjectiveNumber,
                        passedLessons: 0,
                        totalLessons: 0
                    });
                }
            } else {
                if (studentData.currentObjective === objectiveNumber + 1) {
                    await updateDoc(studentDocRef, { currentObjective: studentData.currentObjective - 1 });
                }
            }
        } catch (error) {
            console.error("Error recalculating passed lessons:", error);
        }
    };
    
      
// Inside the handleEditGradeButtonClick function
const handleEditGradeButtonClick = async (lessonId) => {
    if (selectedObjective < selectedStudent.currentObjective) {
        // Show browser popup with the message
        window.alert("You cannot edit previous objectives!");
        return; // Exit the function
    }
    await handleEditGrade();
    setSelectedLessonId(lessonId);
};


    const handleEditGrade = async () => {
        // Check if a lesson is selected
        if (selectedStudent && selectedObjective !== null && selectedLessonId) {
            try {
                // Update the lesson's graded status to false and pass field value to null
                const lessonDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${selectedStudent.id}`, 'objectives', `objective${selectedObjective}`, 'lessons', selectedLessonId);
                await updateDoc(lessonDocRef, {
                    isGraded: false,
                    pass: null // Reset pass field value
                });
                setGraded(false); // Update graded state
    
                // Recalculate passedLessons count
                await recalculatePassedLessons(selectedStudent.id, selectedObjective);
    
                // Update lessonsData to reflect the changes
                const updatedLessonsData = lessonsData.map(lesson => {
                    if (lesson.id === selectedLessonId) {
                        return { ...lesson, isGraded: false, pass: null };
                    }
                    return lesson;
                });
                setLessonsData(updatedLessonsData);
                setPassValue(null); // Update passValue
            } catch (error) {
                console.error("Error editing grade:", error);
            }
        }
    };
    
    
    const fetchPassValue = async (studentDocumentID, objectiveNumber, lessonId) => {
        try {
            const lessonDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${objectiveNumber}`, 'lessons', lessonId);
            const lessonDocSnapshot = await getDoc(lessonDocRef);
            const lessonData = lessonDocSnapshot.data();
            if (lessonData && lessonData.hasOwnProperty('pass')) {
                setPassValue(lessonData.pass);
            } else {
                setPassValue(null); // Reset passValue if 'pass' field doesn't exist
            }
        } catch (error) {
            console.error("Error fetching pass value:", error);
        }
    };
    
    
    const getColorCode = (studentCurrentObjective, objectiveNumber) => {
        if (objectiveNumber < studentCurrentObjective) {
            return 'green';
        } else if (objectiveNumber === studentCurrentObjective) {
            return 'yellow';
        } else {
            return 'red';
        }
    };

    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        fetchStudentLessonData(student.id);
    };
    

    const handleClosePopup = () => {
        setSelectedStudent(null);
    };

    if (loading) {
        return <div className="lds-ellipsis" style={{position: 'absolute', left: '50%', top: '50%'}}><div></div><div></div><div></div><div></div></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!studentsData || studentsData.length === 0) {
        return (
            <div>
                <div>No students found!</div>
                <AddStudent
                    teacherDocumentID={teacherDocumentID}
                    setStudentsData={setStudentsData}
                />
            </div>
        );
    }

    
    const classObjectivesData = {};
    studentsData.forEach(student => {
        if (!classObjectivesData[student.currentObjective]) {
            classObjectivesData[student.currentObjective] = 1;
        } else {
            classObjectivesData[student.currentObjective]++;
        }
    });

    const classObjectives = {
        labels: Object.keys(classObjectivesData).map(objective => `Objective ${objective}`),
        datasets: [{
            label: "Class Objective Progression",
            data: Object.values(classObjectivesData),
            backgroundColor: ['#38023B', '#BBD5ED', '#CCFFCB', '#FFD700', '#FF6347', '#9370DB', '#87CEEB', '#90EE90', '#F4A460', '#FF69B4'],
            borderColor: ['#38023B', '#BBD5ED', '#CCFFCB', '#FFD700', '#FF6347', '#9370DB', '#87CEEB', '#90EE90', '#F4A460', '#FF69B4']
        }]
    };

    return (
        <div className='classDataContainer'>
            <StudentProgressChart
                studentsData={studentsData}
                objectivesData={objectivesData}
                getColorCode={getColorCode}
                handleSelectStudent={handleSelectStudent}
            />
            {selectedStudent && lessonObjectiveData &&(
                <StudentPopup
                    selectedStudent={selectedStudent}
                    lessonObjectiveData={lessonObjectiveData}
                    fetchLessonData={fetchLessonData}
                    setSelectedObjective={setSelectedObjective}
                    handleClosePopup={handleClosePopup}
                    lessonsData={lessonsData}
                    graded={graded}
                    selectedLessonId={selectedLessonId}
                    handleEditGradeButtonClick={handleEditGradeButtonClick}
                    handlePass={handlePass}
                    handleFail={handleFail}
                    passValue={passValue}
                    nextLesson={nextLesson}
                    previousLesson={previousLesson}
                    currentLessonIndex={currentLessonIndex}
                    displayLesson={displayLesson}
                    currentObjective={currentObjective}
                />
            )}

        </div>
    );
};

export default TeacherFetch;
