import React from 'react';
import '../../css/componentStyles.css';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './progressBar';

export default function Lessons({
    teacherDocumentID = '',
    studentDocumentID = '',
    studentCurrentObjectiveNumber = '',
    currentObjectivePhonemicTranslations = '',
    currentObjectiveEnglishTranslations = '',
    allEnglishTranslations = '',
    allPhonemicTranslations = '',
    passedLessons,
    totalLessons,
    cachedData = {
        currentObjectiveEnglishTranslations: [],
        currentObjectivePhonemicTranslations: [],
        allEnglishTranslations: [],
        allPhonemicTranslations: [],
    },
}){
    const navigate = useNavigate();

    function toNextLesson (){
        console.log(studentDocumentID, teacherDocumentID)
        navigate('/lessonSession', {
            state: { 
                studentID : studentDocumentID, 
                teacherID : teacherDocumentID, 
                studentCurrentObjectiveNumber : studentCurrentObjectiveNumber,
                passedLessons : passedLessons,
                currentObjectivePhonemicTranslations : currentObjectivePhonemicTranslations,
                currentObjectiveEnglishTranslations : currentObjectiveEnglishTranslations,
                totalLessons : totalLessons,
                allEnglishTranslations : allEnglishTranslations,
                allPhonemicTranslations : allPhonemicTranslations,

            }});
    }
    

    return (
        <div className='studentLessonsBody'>
            <div className='lessonsContainer'>
                <div className='lessonCard'>
                    <p>Next Lesson: Unit {studentCurrentObjectiveNumber}</p>
                    <div>
                        <h3>Phoneme(s): {currentObjectivePhonemicTranslations}</h3>
                        <h3>Sounds(s): {currentObjectiveEnglishTranslations} <button onClick = {toNextLesson}> Go! </button></h3>
                    </div>
                </div>
            </div>

            <div className='studentStats'>
                <h3>Unit {studentCurrentObjectiveNumber} Progress</h3>
                <ProgressBar 
                    passedLessons={passedLessons}
                />
            </div>
        </div>
    )
}