import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faTree, faHouse } from '@fortawesome/free-solid-svg-icons';

import '../../css/componentStyles.css';

library.add( faSchool, faTree, faHouse );


export default function SettingSelect({ onSelectSetting }) {
    
    // Function to handle button clicks
    const handleButtonClick = (event) => {
        onSelectSetting(event.target.value);
    };

    return(
        <div className='settingSelect' id='settingSelect'>
            <button className='settingBtn' value={'park'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-tree" />
                Park
            </button>
            <button className='settingBtn' value={'house'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-house" />
                House
            </button>
            <button className='settingBtn' value={'school'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-school" />
                School
            </button>
        </div>
    )
}

