import React from 'react';
import '../../css/componentStyles.css';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


export default function AudioRecord() {
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const uploadAudio = (blob) => {
    const storageRef = ref(storage, `audio/recordedLessons/cheitman.mp3`); // Unique name for each recording, now set to name after username (MUST CHANGE FOR THIS TO WORK)
    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress if needed
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          // Optionally, you can set state or perform any other actions with the download URL
        });
      }
    );
  };

  return (
    <div>
      <AudioRecorder
        onRecordingComplete={(blob) => {
          uploadAudio(blob);
        }}
        recorderControls={recorderControls}
        showVisualizer={true}
      />
      <br />
      <button onClick={recorderControls.stopRecording}>Stop recording</button>
      <br />
    </div>
  );
}
