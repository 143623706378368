import React from 'react';
import './studentProgressChart.css';

const StudentProgressChart = ({ 
    studentsData, 
    objectivesData, 
    getColorCode, 
    handleSelectStudent
    }) => {
    return (
        <div className="studentProgressChart">
            <h2>Student Progress</h2>
            <table className="chart-table">
                <thead>
                    <tr>
                        <th></th>
                        {objectivesData.map(objective => (
                            <th key={objective.objectiveNumber}>Unit {objective.objectiveNumber}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {studentsData.map(student => (
                        <tr key={student.firstName}>
                            <th className="studentNameButton" scope="row">
                                <button onClick={() => { handleSelectStudent(student) }}>{`${student.firstName} ${student.lastName}`}</button>
                            </th>
                            {objectivesData.map(objective => (
                                <td key={`${student.firstName}-${objective.objectiveNumber}`} style={{ backgroundColor: getColorCode(student.currentObjective, objective.objectiveNumber), borderRadius: '20px' }}></td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StudentProgressChart;
