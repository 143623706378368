import React from 'react';

// Updated CSS for the progress bar
const styles = {
  container: {
    height: '40px',
    width: '100%', // Make sure the container takes the full width of its parent
    backgroundColor: '#e0e0de',
    borderRadius: '50px',
    position: 'relative', // Positioned relative to allow absolute positioning inside
    overflow: 'hidden', // Ensure the filler doesn't overflow the rounded corners
    marginTop: '10px'
  },
  filler: {
    height: '100%',
    width: '0%',
    backgroundColor: '#4db253',
    borderRadius: 'inherit',
    transition: 'width 0.5s ease-in-out',
  },
  label: {
    position: 'absolute', // Position the label absolutely to ensure it's placed over the filler and container
    top: '45%',
    left: '10px', // Adjust left as needed to keep the label inside the container
    transform: 'translateY(-50%)', // Center the label vertically
    color: 'black',
    fontWeight: 'bold',
  },
};

const ProgressBar = ({ passedLessons }) => {
  const safeValue = Math.min(Math.max(passedLessons, 0), 4);
  let percentage = (safeValue / 4) * 100;

  // Adjust the percentage to ensure that the label is not hidden when progress is low
  if (!passedLessons || passedLessons == 0) {
    passedLessons = 0;
    percentage = 5; // Keep at 0% if there's no progress
  }

  return (
    <>
    <div style={styles.container}>
      <div style={{ ...styles.filler, width: `${percentage}%` }}></div>
      <span style={styles.label}>{`${passedLessons}/4`}</span>
    </div>
    </>
  );
};

export default ProgressBar;
