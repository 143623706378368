import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { auth, firestore } from '../firebase.js';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/babblebot.png';
import '../css/App.css';

const Signup = () => {
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [classCode, setClassCode] = useState("");
    const [step, setStep] = useState(1);
    const [gradeLevel, setGradeLevel] = useState("");
    const [classProgression, setClassProgression] = useState("");
    const [classPrivacy, setClassPrivacy] = useState("");
    const [curriculumOption, setCurriculumOption] = useState("");
    const [curriculums, setCurriculums] = useState([]);
    const [showCreateCurriculum, setShowCreateCurriculum] = useState(false);
    const [droppedItems, setDroppedItems] = useState({});
    const [phonemicStructures, setPhonemicStructures] = useState({});
    const [objectives, setObjectives] = useState(0);
    let classCodeLength = 5;
    const navigate = useNavigate();

    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength)).toUpperCase();
            counter += 1;
        }
        setClassCode(result);
        return result;
    }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            }
        });
    }, []);

    useEffect(() => {
        const fetchCurriculums = async () => {
            const curriculumsCollectionRef = collection(firestore, 'curriculums');
            const curriculumSnapshot = await getDocs(curriculumsCollectionRef);
            const fetchedCurriculums = curriculumSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCurriculums(fetchedCurriculums);
        };
        fetchCurriculums();
    }, [gradeLevel]);

    useEffect(() => {
        const fetchPhonemicStructures = async () => {
            const typesCollectionRef = collection(firestore, 'phonemicStructures');
            const typesSnapshot = await getDocs(typesCollectionRef);
            const types = typesSnapshot.docs.map(doc => doc.id);

            let structures = {};
            for (const type of types) {
                const structuresCollectionRef = collection(firestore, 'phonemicStructures', type, 'structures');
                const structuresSnapshot = await getDocs(structuresCollectionRef);
                structures[type] = structuresSnapshot.docs.map(doc => doc.data());
            }

            setPhonemicStructures(structures);
        };

        fetchPhonemicStructures();
    }, []);

    const writeUser = async (user, classConfigured) => {
        const newClassCode = makeid(classCodeLength);
        const userID = user.uid;
        const userData = {
            firstName,
            lastName,
            email: registerEmail,
            classCode: newClassCode,
            gradeLevel: classConfigured ? gradeLevel : null,
            classProgression: classConfigured ? classProgression : null,
            classPrivacy: classConfigured ? classPrivacy : null,
            curriculumName: classConfigured ? (showCreateCurriculum ? userID : curriculumOption) : null,
            classConfigured,
        };

        await setDoc(doc(firestore, 'users', userID), userData);
        if (classConfigured && showCreateCurriculum) {
            await handleSaveCurriculum(userID);
        }
    }

    const handleSaveCurriculum = async (teacherDocumentID) => {
        try {
            const curriculumDocRef = doc(firestore, 'curriculums', teacherDocumentID);
            await setDoc(curriculumDocRef, {
                gradeLevel: gradeLevel,
                numberOfObjectives: objectives,
            });
    
            const objectivesCollectionRef = collection(curriculumDocRef, 'objectives');
            for (let i = 1; i <= objectives; i++) {
                const objectiveDocRef = doc(objectivesCollectionRef, `objective${i}`);
                await setDoc(objectiveDocRef, {
                    objectiveNumber: i,
                    phonemicStructures: droppedItems[i] || []
                });
            }
    
            alert('Curriculum created successfully!');
        } catch (error) {
            console.error('Error creating curriculum:', error);
            alert('Error creating curriculum');
        }
    };

    const register = async (classConfigured = true) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
            await writeUser(userCredential.user, classConfigured);
            navigate('/TeacherDash');
        } catch (error) {
            console.log(error.message);
        };
    };

    const handleNext = () => {
        setStep(2);
    }

    const handleBack = () => {
        setFirstName("");
        setLastName("");
        setRegisterEmail("");
        setRegisterPassword("");
        setGradeLevel("");
        setClassProgression("");
        setClassPrivacy("");
        setCurriculumOption("");
        setShowCreateCurriculum(false);
        setDroppedItems({});
        setObjectives(0);
        setStep(1);
    }

    const handleCurriculumChange = (event) => {
        setCurriculumOption(event.target.value);
        if (event.target.value === 'createYourOwn') {
            setShowCreateCurriculum(true);
        } else {
            setShowCreateCurriculum(false);
        }
    }

    const handleDrop = (objectiveNumber, item) => {
        setDroppedItems((prevItems) => ({
            ...prevItems,
            [objectiveNumber]: [...(prevItems[objectiveNumber] || []), item],
        }));
    };

    const handleObjectiveNumberChange = (e) => {
        const objectiveCount = Math.min(Math.max(parseInt(e.target.value), 1), 60);
        setObjectives(objectiveCount);
        setDroppedItems(Array.from({ length: objectiveCount }, () => []));
    };

    return (
        <div className='signupPage'>
            <div className='registerContainer'>
                {step === 1 && (
                    <>
                        <h3 className='signUpHeader'>Welcome to Babblebot!</h3>
                        <input className='fName registerInput' placeholder='first name' onChange={(event) => setFirstName(event.target.value)} />
                        <input className='lName registerInput' placeholder='last name' onChange={(event) => setLastName(event.target.value)} />
                        <input className='email registerInput' placeholder='email' onChange={(event) => setRegisterEmail(event.target.value)} />
                        <input className='password registerInput' placeholder='password' onChange={(event) => setRegisterPassword(event.target.value)} />
                        <button className='registerBtn' onClick={handleNext}>Next</button>
                    </>
                )}

                {step === 2 && (
                    <>
                        <h3 className='signUpHeader'>Classroom Details</h3>
                        <label htmlFor='gradeLevel'>Grade Level</label>
                        <select id='gradeLevel' value={gradeLevel} onChange={(event) => setGradeLevel(event.target.value)}>
                            <option value=''>Select Grade Level</option>
                            {['Homeschool', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(level => (
                                <option key={level} value={level}>{level}</option>
                            ))}
                        </select>

                        <label htmlFor='classProgression'>Class Progression</label>
                        <select id='classProgression' value={classProgression} onChange={(event) => setClassProgression(event.target.value)}>
                            <option value=''>Select Class Progression</option>
                            <option value='fixed'>Fixed</option>
                            <option value='free'>Free</option>
                        </select>

                        <label htmlFor='classPrivacy'>Class Privacy</label>
                        <select id='classPrivacy' value={classPrivacy} onChange={(event) => setClassPrivacy(event.target.value)}>
                            <option value=''>Select Class Privacy</option>
                            <option value='public'>Public</option>
                            <option value='private'>Private</option>
                        </select>

                        <label htmlFor='curriculum'>Curriculum</label>
                        <select id='curriculum' value={curriculumOption} onChange={handleCurriculumChange}>
                            <option value=''>Select Curriculum</option>
                            {curriculums.map(curriculum => (
                                <option key={curriculum.id} value={curriculum.id}>{curriculum.id}</option>
                            ))}
                            <option value='createYourOwn'>Create Your Own</option>
                        </select>

                        {showCreateCurriculum && (
                            <div>
                                <label htmlFor="objectives">Objectives</label>
                                <input
                                    type="number"
                                    id="objectives"
                                    value={objectives}
                                    onChange={handleObjectiveNumberChange}
                                    min="1"
                                    max="60"
                                    style={{ width: '100px', margin: '10px 0' }}
                                />
                            </div>
                        )}

                        <div style={{ display: 'flex', marginTop: '20px' }}>
                            <div style={{ flex: '1', overflowY: 'scroll', maxHeight: '400px' }}>
                                {Object.entries(phonemicStructures).map(([type, structures]) => (
                                    <div key={type}>
                                        <h3>{type}</h3>
                                        {structures.map((structure, index) => (
                                            <div
                                                key={index}
                                                draggable
                                                onDragStart={(e) => e.dataTransfer.setData('text/plain', JSON.stringify(structure))}
                                                style={{ border: '1px solid black', margin: '5px', padding: '5px', width: '150px' }}
                                            >
                                                {structure.phonemicTranslation}, {structure.englishTranslation}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div style={{ flex: '1', overflowY: 'scroll', maxHeight: '400px' }}>
                                {Array.from({ length: objectives }, (_, i) => (
                                    <div
                                        key={i + 1}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            const data = e.dataTransfer.getData('text/plain');
                                            handleDrop(i + 1, JSON.parse(data));
                                        }}
                                        style={{ border: '1px solid blue', margin: '5px', padding: '5px', width: '300px' }}
                                    >
                                        <h5>Objective {i + 1}</h5>
                                        {droppedItems[i + 1]?.map((item, index) => (
                                            <div key={index}>{item.phonemicTranslation}, {item.englishTranslation}</div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button className='registerBtn' onClick={() => register(true)}>Submit</button>
                        <button className='registerBtn' onClick={() => register(false)}>I will set up my classroom later</button>
                        <button className='backBtn' onClick={handleBack}>Back</button>
                    </>
                )}

                <p>Already have an account?</p>
                <Link className='signUpLink' to='/signin'>Login</Link>
            </div>

            <div>
                <Link to='/'><img src={logo} className='registerLogo' alt='Babblebot Logo' /></Link>
            </div>
        </div>
    );
}

export default Signup;
