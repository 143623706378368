import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import CreateCurriculum from "../components/teacher/modifyCurriculum";
import '../css/curriculum.css';
import ModifyCurriculum from "../components/teacher/modifyCurriculum";

const Curriculum = () => {

    const { firstName, lastName, teacherDocumentID, classCode } = useParams();

    return (
        <div style={{minHeight: '90vh', backgroundColor: 'beige', display: 'flex'}}>
            <Sidebar 
                firstName={firstName} 
                lastName={lastName} 
                teacherDocumentID={teacherDocumentID} 
                classCode={classCode} 
            />
            <div className="curriculumPage">
                <h2 className="pageHeading">Curriculum Settings</h2>
                <ModifyCurriculum
                    teacherDocumentID= {teacherDocumentID}
                />
            </div>
        </div>
    )
}

export default Curriculum 