import React from "react";
import './howItWorks.css';

import StepOneArm from '../../images/STEPONEARM.svg';
import LessonSession from '../../images/lessonSession.png';
import StudentDash from '../../images/studentDashboard.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSplotch } from '@fortawesome/free-solid-svg-icons';

library.add( faSplotch );

export default function HowItWorks() {
    return (
        <div className="howItWorksContainer">
            <h2>How it works?</h2>

            {/* Step 1 */}
            <div className="steps stepOne">
                <div className="infoBlob">
                    <FontAwesomeIcon icon="fa-solid fa-splotch" className="blob blobStepOne"/>
                    <p>Students access their pre-configured accounts to view their dashboard!</p>
                </div>

                <div className="videoContainer">
                    <div className="video" style={{backgroundImage: `url(${StudentDash})`, backgroundSize: 'cover', borderRadius: '12px', backgroundRepeat: 'no-repeat'}}>
                    </div>
                </div>
            </div>

            {/* Step 2 */}
            <div className="steps stepTwo">
                <div className="videoContainer">
                    <div className="video2" style={{backgroundImage: `url(${LessonSession})`, backgroundSize: 'cover', borderRadius: '12px', backgroundRepeat: 'no-repeat'}}>
                    </div>
                </div>

                <div className="infoBlobTwo">
                    <FontAwesomeIcon icon="fa-solid fa-splotch" className="blob blobStepTwo"/>
                    <p>Students generate stories based on particular scope and sequence objectives
                         as assigned by their teacher!</p>
                </div>
            </div>

            {/* Step 3 */}
            <div className="steps stepThree">
                <div className="infoBlobThree">
                    <FontAwesomeIcon icon="fa-solid fa-splotch" className="blob blobStepThree"/>
                    <p>Students select their topics of interest for their custom story to be generated
                         around, and then begin reading out loud!</p>
                </div>

                <div className="videoContainer">
                    <div className="video3"></div>
                </div>
            </div>

            {/* Step 4 */}
            <div className="steps stepFour">
                <div className="videoContainer">
                    <div className="video4"></div>
                </div>

                <div className="infoBlobFour">
                    <FontAwesomeIcon icon="fa-solid fa-splotch" className="blob blobStepFour"/>
                    <p>Metrics and statistics are provided to the teacher for them to assess 
                       areas of weakness without the guesswork!</p>
                </div>
            </div>
        </div>
    )
}