import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { firestore } from '../../firebase.js';
import * as XLSX from 'xlsx';

import '../teacher/teacher.css';

const ClassUpload = ({ teacherDocumentID }) => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const generateRandomPassword = () => {
        let password = '';
        for (let i = 0; i < 5; i++) {
            password += Math.floor(Math.random() * 10); // Generate a random digit between 0 and 9
        }
        return password;
    };
    
    const handleSubmit = async () => {
        if (!file || !teacherDocumentID) return;
    
        try {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async () => {
                const data = reader.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
                const studentsToAdd = dataArray.slice(1).map(row => {
                    return {
                        firstName: row[0], // Assuming first column is first name
                        lastName: row[1], // Assuming second column is last name
                    };
                });
    
                const teacherRef = collection(firestore, 'users', teacherDocumentID, 'students');
    
                for (const student of studentsToAdd) {
                    const { firstName, lastName } = student;
    
                    if (!firstName || !lastName) {
                        console.error('Skipping student:', student);
                        continue;
                    }
    
                    const username = `${firstName.charAt(0).toLowerCase()}${lastName.slice(0, 4).toLowerCase()}`;
                    const password = generateRandomPassword();
                    await addDoc(teacherRef, {
                        firstName,
                        lastName,
                        username,
                        password,
                    });
                }
    
                alert('Students uploaded successfully!');
            };
        } catch (error) {
            console.error('Error uploading students:', error);
            alert('Error uploading students. Please try again.');
        }
    };    

    return (
        <div className='uploadContainer'>
            <input className='uploadClassInput' type="file" accept=".xlsx" onChange={handleFileChange} />
            <button className='uploadBtn' onClick={handleSubmit}>Upload Roster</button>
        </div>
    );
};

export default ClassUpload;
