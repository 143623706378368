import { useState, useEffect } from "react";
import OpenAI from 'openai';
import { firestore } from "../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import './newDemo.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRightLong, faArrowLeftLong);

export default function StoryGenPhonemic() {
  const [selectedStructure, setSelectedStructure] = useState('');
  const [structures, setStructures] = useState([]);
  const [textLoading, setTextLoading] = useState(false);
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [openAIOutput, setOpenaiOutput] = useState('');

  const fetchStructures = async () => {
    try {
      const structureTypes = ["consonantblends", "vowels", "consonants"];
      let fetchedStructures = [];

      for (const type of structureTypes) {
        const structuresCollectionRef = collection(firestore, 'phonemicStructures', type, 'structures');
        const structuresSnapshot = await getDocs(structuresCollectionRef);
        structuresSnapshot.forEach(doc => {
          const data = doc.data();
          if (data.phonemicTranslation && data.englishTranslation) {
            fetchedStructures.push({
              id: doc.id,
              type: type,
              phonemicTranslation: data.phonemicTranslation,
              englishTranslation: data.englishTranslation,
            });
          }
        });
      }

      setStructures(fetchedStructures);
    } catch (error) {
      console.error("Error fetching structures:", error);
    }
  };

  useEffect(() => {
    fetchStructures();
  }, []);

  const handleStorySubmit = async () => {
    if (!selectedStructure) {
      console.error("Please select a phonemic structure before generating a story.");
      return;
    }

    setTextLoading(true);

    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });
    const selected = structures.find(s => s.id === selectedStructure);

    const promptTemplate = `
      Please write a short, simple story for kindergarten-aged children that is highly concentrated with the phonemic structure "${selected.phonemicTranslation}".
      The story should be approximately 40 words long.
      Ensure that the story is simple and suitable for young children, without using any complex vocabulary or any commas, quotation marks, apostrophes, or question marks.
    `;

    const stream = await openai.beta.chat.completions.stream({
      model: 'gpt-4',
      messages: [{ role: 'user', content: promptTemplate }],
      stream: true,
    });

    let generatedStory = '';

    stream.on('content', (delta) => {
      generatedStory += delta;
      setOpenaiOutput(generatedStory.trim());
      const storySentences = generatedStory.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.trim() + '.');
      setSentences([...storySentences]);
      setTextLoading(false);
    });
  };

  const handleNextSentence = () => {
    setCurrentSentenceIndex((prevIndex) => Math.min(prevIndex + 1, sentences.length - 1));
  };

  const handlePrevSentence = () => {
    setCurrentSentenceIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className="newDemo">
      <div className="structureContainer">
        <p>Lesson</p>
        <select className='structureInput' onChange={(e) => setSelectedStructure(e.target.value)}>
          <option value="">Select Phonemic Structure</option>
          {structures.map((structure) => (
            <option key={structure.id} value={structure.id}>
              {structure.phonemicTranslation}, {structure.englishTranslation}
            </option>
          ))}
        </select>
        <div id="buttonContainer">
          <button className='generateBtn' onClick={handleStorySubmit}>Generate</button>
        </div>
      </div>
      {textLoading ? (
        <>
          <h3 style={{ fontSize: '30px', color: '#292F36' }}>Generating your personal story!</h3>
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </>
      ) : (
        <>
          {openAIOutput && (
            <div className='generatedContentSection'>
              <div className="generatedStoryArea">
                <p>{sentences[currentSentenceIndex]}</p>
                <div>
                  <button onClick={handlePrevSentence} disabled={currentSentenceIndex === 0}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-arrow-left-long" /></button>
                  <button onClick={handleNextSentence} disabled={currentSentenceIndex === sentences.length - 1}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-arrow-right-long" /></button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
