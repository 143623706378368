//fetching transcripts based on username input and username field of filename 

import { useState } from 'react';
import { firestore } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import '../../css/componentStyles.css';

const TranscriptionsFetch = () => {
    const [studentUsername, setStudentUsername] = useState('');
    const [transcriptionData, setTranscriptionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTranscription = async () => {
        if (studentUsername.trim() !== '') {
            setLoading(true);
            try {
                const transcriptionsRef = collection(firestore, 'transcriptions');
                const q = query(transcriptionsRef, where("fileName", "==", `audio/recordedLessons/${studentUsername}.mp3`));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const transcriptionData = querySnapshot.docs[0].data().transcription[0]; // Accessing the first element of the array
                    setTranscriptionData(transcriptionData);
                } else {
                    setTranscriptionData(null);
                    console.log("No such document!");
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching transcription data:", error);
                setError(error);
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        setStudentUsername(e.target.value);
    };

    return (
        <div>
            <center>
                <h2>Enter Student Username</h2>
            </center>

            <input
                type="text"
                value={studentUsername}
                onChange={handleInputChange}
                placeholder="Enter student username"
            />

            <button onClick={fetchTranscription}>Fetch Transcription</button>

            {loading && <div>Loading...</div>}

            {error && <div>Error: {error.message}</div>}

            {transcriptionData && (
                <div>
                    <h3>Transcription Data</h3>
                    <p>{transcriptionData}</p>
                </div>
            )}
        </div>
    );
};

export default TranscriptionsFetch;
