import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase.js';
import './configureClassroom.css';

const ConfigureClassroom = ({ teacherDocumentID }) => {
    const [classProgression, setClassProgression] = useState('');
    const [gradeLevel, setGradeLevel] = useState('');
    const [classPrivacy, setClassPrivacy] = useState('');
    const [requiredPassesToProgress, setRequiredPassesToProgress] = useState(null);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    const fetchTeacherData = async () => {
        setLoading(true);
        try {
            const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
            const teacherDocSnapshot = await getDoc(teacherDocRef);
            if (teacherDocSnapshot.exists()) {
                const data = teacherDocSnapshot.data();
                setClassProgression(data.classProgression || '');
                setGradeLevel(data.gradeLevel || '');
                setClassPrivacy(data.classPrivacy || '');
                setRequiredPassesToProgress(data.requiredPassesToProgress ?? null);
            } else {
                // Handle case where the document does not exist
                setClassProgression('');
                setGradeLevel('');
                setClassPrivacy('');
                setRequiredPassesToProgress(null);
            }

            const studentsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students');
            const studentsSnapshot = await getDocs(studentsCollectionRef);
            const studentsList = studentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setStudents(studentsList);
        } catch (error) {
            console.error("Error fetching teacher data: ", error);
        } finally {
            setLoading(false);
            setShowSettings(true);
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
            await updateDoc(teacherDocRef, {
                classProgression,
                gradeLevel,
                classPrivacy,
                requiredPassesToProgress: requiredPassesToProgress !== null ? requiredPassesToProgress : null
            });
            setShowSettings(false);
        } catch (error) {
            console.error("Error saving changes: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='configureClassContainer'>
            <h3>Classroom Settings</h3>
            {!showSettings ? (
                <button className='editClassBtn' onClick={fetchTeacherData}>Edit</button>
            ) : (
                loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className='pacingOptionsContainer'>
                        <div>
                            <label>Class Progression:</label>
                            <select value={classProgression} onChange={e => setClassProgression(e.target.value)}>
                                {classProgression === '' && <option value="">Select Progression</option>}
                                <option value="Fixed">Fixed</option>
                                <option value="Free">Free</option>
                            </select>
                        </div>
                        <div>
                            <label>Grade Level:</label>
                            <select value={gradeLevel} onChange={e => setGradeLevel(e.target.value)}>
                                {gradeLevel === '' && <option value="">Select Grade Level</option>}
                                {['K', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Homeschool'].map(level => (
                                    <option key={level} value={level}>{level}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Classroom Privacy:</label>
                            <select value={classPrivacy} onChange={e => setClassPrivacy(e.target.value)}>
                                {classPrivacy === '' && <option value="">Select Class Privacy</option>}
                                <option value="public">Public</option>
                                <option value="private">Private</option>
                            </select>
                        </div>
                        <div>
                            <label>Required Passes to Progress:</label>
                            <select value={requiredPassesToProgress !== null ? requiredPassesToProgress : ''} onChange={e => setRequiredPassesToProgress(Number(e.target.value))}>
                                {requiredPassesToProgress === null && <option value="">Select Required Passes</option>}
                                {[1, 2, 3, 4, 5, 6, 7, 8].map(passes => (
                                    <option key={passes} value={passes}>{passes}</option>
                                ))}
                            </select>
                        </div>
                        <button className='confirmClassBtn' onClick={handleSave}>Confirm</button>
                    </div>
                )
            )}
        </div>
    );
};

export default ConfigureClassroom;
