import React from 'react';
import { Link } from 'react-router-dom';
import TeacherSignIn from '../components/teacher/teacherSignIn.jsx';
import StudentSignIn from '../components/student/studentSignIn.jsx';
import BabbleExcited from '../images/BabbleExcited.png';
import '../css/App.css';

const Signup = () => {
    return (
        <div className='signInPage'>
            <div className='signInRight'>
                <StudentSignIn />
            </div>
        </div>
    );
};

export default Signup;
