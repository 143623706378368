import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import ConfigureClassroom from "../components/teacher/configureClassroom";
import '../css/settings.css'

export default function Settings () {

    const { firstName, lastName, teacherDocumentID, classCode } = useParams();

    return (
        <div style={{height: '90vh', backgroundColor: 'beige', display: 'flex'}}>
            <Sidebar firstName={firstName} lastName={lastName} teacherDocumentID={teacherDocumentID} classCode={classCode}/>
            <div className="settingsPage">
                <h2 className="pageHeading">Classroom Settings</h2>
                <div className="settingsContainer">
                    <ConfigureClassroom 
                        teacherDocumentID={teacherDocumentID}
                    />
                </div>
            </div>
        </div>
    )
}