import React from 'react';
import TeacherSignIn from '../components/teacher/teacherSignIn.jsx';
import '../css/App.css';

const TeacherSign = () => {
    return (
        <div className='signInPage'>

            <div className='signInRight'>
                <TeacherSignIn />
            </div>
        </div>
    );
};

export default TeacherSign;