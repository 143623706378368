import React, { useState } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase.js';
import { Link } from 'react-router-dom';

import './teacherSignIn.css';

const TeacherSignIn = () => {
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [user, setUser] = useState("");
    const navigate = useNavigate();

    onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });

    const login = async () => {
        try {
            const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            navigate('/TeacherDash');
        } catch (error) {
            document.getElementById('loginError').innerHTML = 'Invalid Email or Password';
            console.log(error.message);
        }
    };

    const logout = async () => {
        await signOut(auth).then(setUser(''));
    };

    return (
        <div className='teacherSignInContainer'>
            <h3 className='teacherSignInHeader'>Welcome Back Teachers!</h3>
            <input id='email' className='teacherSignInInput' placeholder='email' onChange={(event) => {
                setLoginEmail(event.target.value);
            }}/>
            <input id='password' className='teacherSignInInput' type='password' placeholder='password' onChange={(event) => {
                setLoginPassword(event.target.value);
            }}/>
            <div id='loginError'></div>

            <button className='teacherSignInButton' onClick={async () => {await login();}} to='/teacherDash'>Login</button>

            <p>Don't have an account? <Link className='signUpLink' to='/signup'>Sign up</Link></p>
        </div>
    );
};

export default TeacherSignIn;
