import React from 'react';
import { Link } from 'react-router-dom';
import './startToday.css';


export default function StartToday() {
    return (
        <div className='startTodayContainer'>
            <h2>Why not start today?</h2>
            <p>If you have any questions regarding BabbleBot, 
                feel free to contact us at, <a href= "mailto: babblebot.learning@gmail.com">babblebot.learning@gmail.com</a>.
                Otherwise, begin your learning with BabbleBot and transform your
                classroom!
            </p>
            <Link className='ctaButton' to='/signup'>Get Started!</Link>
        </div>
    )
}