import React from 'react';
import AudioRecording from '../components/firebase/audioRecording';
import '../css/componentStyles.css';
import '../css/App.css';
import '../css/index.css';
import TranscriptionsFetch from '../components/firebase/fetchTranscriptions';
import TeacherFetch from '../components/teacher/teacherPageFetch';
import UploadUsers from '../components/firebase/classUpload';
import Signin from '../components/student/studentSignIn';

const Tests = () => {
    return (
      <div className='App'>
        <h1>Student Login test</h1>
        
        <Signin />

        <h1>Teacher Fetch Component</h1>
        
        <TeacherFetch />

        <hr />
        <h1>Upload Audio to Firebase</h1>
        
        <AudioRecording />
        
        <hr />

        <h1>Transcription Fetch</h1>

        <TranscriptionsFetch />

        <h1>Upload Users</h1>

        <UploadUsers />


      </div>
      
      );
  };
  
export default Tests;