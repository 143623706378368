import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faXTwitter, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

import logo from '../images/babblebot.png';
import '../css/layout.css';
//import '../css/componentStyles.css';
//import '../css/App.css';


library.add( faBars, faXmark, faArrowRight, faInstagram, faXTwitter, faSquareFacebook )

const Layout = () => {

  const [user, setUser] = useState("");
  let linkText = 'Login';
  let linkValue = '/signin';
  

  onAuthStateChanged(auth, (currentUser) => { // keeps user current when refreshing / changing state of site
      setUser(currentUser);
  })

  if (!user){
    linkText = 'Login' //send user back to signup if not authenticated
    linkValue = '/signin'
  } else {
    linkText = 'Dashboard'
    linkValue = '/teacherdash' 
  }

  function openMenu() {
    let menu = document.getElementById("menu-items-mobile");
    if (menu.style.display === "none"){
      menu.style.display = "block";
    } else {
      menu.style.display = "none";
    }
  }

  return (
    <>
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap" rel="stylesheet"></link>
      <nav>
        <div className="navbar">
          {/* Desktop Navbar */}
          {window.location.pathname === '/studentDash' || window.location.pathname === '/lessonSession' ? (
            <div className="menu-items-desktop">
            <Link to="/"><img className='menulogo' alt='Babblebot Robot' src={logo} /></Link>
            <ul>
              <li><Link className="link-desktop navBtn" to='/'>Logout<FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIconNav' /></Link></li>
            </ul>
          </div>
          ) : (
            <div className="menu-items-desktop">
                <Link to="/"><img className='menulogo' alt='Babblebot Robot' src={logo} /></Link>
                <ul>
                  <li><Link className="link-desktop" to="/">Home</Link></li>
                  <li><a className="link-desktop active" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">About</a></li>
                  <li><Link className='link-desktop' to='/signup'>Signup</Link></li>
                  <li><Link className="link-desktop navBtn" to={linkValue}>{linkText}<FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIconNav' /></Link></li>
                </ul>
              </div>
          )}
          <div className="navMobile">
              {/* Mobile Navbar */}
              <div className="logo">
                <Link to="/"><img className='logo' alt='Babblebot Robot' src={logo} /></Link>
              </div>
              <div className="hamburgerLines" id="hamburgerLines" onClick={openMenu}>
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
              </div>  
          </div>
          <div className="menu-items-mobile" id='menu-items-mobile'>
              <li><Link className="link" to="/">Home</Link></li>
              <hr className='nav-hr'/>
              <li><a className="link" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">About</a></li>
              <hr className='nav-hr'/>
              <li><Link className="link" to="/signup">Signup</Link></li>
              <hr className='nav-hr'/>
              <li><Link className="link" to="/signin">Login</Link></li>
              <hr className='nav-hr'/>
            </div>
        </div>
      </nav>

      <Outlet />

      <footer>
        <div className='footerContainer'>

          <div className='footerAbout'>
            <p className='footerHeader'>About</p>
            <p className='footerText'>Babblebot is a FREE online educational resource, aiming to give students 
              and educators a fun and central way to learn phonemes. </p>
          </div>

          <div className='pageLinks'>
            <p className='footerHeader'>Navigation</p>
            <ul className='footerList'>
              <Link className="footerLink" to="/"><li>Home <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li></Link>
              <a className="footerLink" href="https://babblebot.notion.site/BabbleBot-Documentation-0e0816f3653142d0ac237a30dc6a92d4" rel='noreferrer' target="_blank">
                <li>About <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li>
              </a>
              <Link className="footerLink" to="/signup"><li>Signup <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li></Link>
              <Link className="footerLink" to="/signin"><li>Login <FontAwesomeIcon icon="fa-solid fa-arrow-right" className='arrowIcon' /></li></Link>

            </ul>
          </div>

          <div className="socials">
            <p className='footerHeader'>Follow Us!</p>
            <a className='footerLink' href="instagram.com"><FontAwesomeIcon icon="fa-brands fa-instagram" className="footerBrands" /></a>
            <a className='footerLink' href="x.com"><FontAwesomeIcon icon="fa-brands fa-x-twitter" className='footerBrands'/></a>
            <a className='footerLink' href="facebook.com"><FontAwesomeIcon icon="fa-brands fa-square-facebook" className='footerBrands'/></a>
          </div>

          <div className="Copyright">
            <p>&copy; Copyright 2024 | All Rights Reserved</p>
          </div>
        </div>
      </footer> 
      </>   
  )
};

export default Layout;
