import React from 'react';
import LessonDetailsDisplay from './LessonDetailsDisplay';
import PassFailButtons from './PassFailButtons';
import PrevNextLessonButtons from './PrevNextLessonButtons';
import AudioPlayer from './AudioPlayer';

const StudentPopup = ({
    selectedStudent,
    lessonObjectiveData,
    fetchLessonData,
    setSelectedObjective,
    handleClosePopup,
    lessonsData,
    graded,
    selectedLessonId,
    handleEditGradeButtonClick,
    handlePass,
    handleFail,
    passValue,
    nextLesson,
    previousLesson,
    currentLessonIndex,
    displayLesson,
    currentObjective
}) => {

    return (
        <div className="studentPopupContainer">
            <span className="close" onClick={handleClosePopup}>&times;</span>
            <div className="studentPopupContent">
                <h2>{`${selectedStudent.firstName} ${selectedStudent.lastName}`}</h2>
                <p>Current Objective: {currentObjective}</p>
                <p>studentDocID: {selectedStudent.id}</p>
            </div>
            <div className='objectiveContentContainer'>
                {/* Objective selection buttons */}
                <div className='objectiveButtonContainer'>
                    {lessonObjectiveData.map(objective => (
                        <React.Fragment key={objective.objectiveNumber}>
                            <button className='objectiveButton' onClick={() => {
                                fetchLessonData(objective.objectiveNumber, selectedStudent.id);
                                setSelectedObjective(objective.objectiveNumber); // Corrected state update
                            }} key={objective.objectiveNumber}>
                                Objective {objective.objectiveNumber}
                            </button>
                        </React.Fragment>
                    ))}
                </div>
                <hr />
                {/* Lesson details */}
                <div className='dataContainer'>
                    {lessonsData.length > 0 && (
                        <LessonDetailsDisplay 
                            graded={graded}
                            lesson={lessonsData[currentLessonIndex]} 
                            passValue={passValue} 
                        />
                    )}
                    <div className='lessonDisplayBottomBtns'>
                    <PassFailButtons
                        graded={graded}
                        handlePass={() => handlePass(selectedLessonId)}
                        handleFail={() => handleFail(selectedLessonId)}
                        handleEditGradeButtonClick={() => handleEditGradeButtonClick(selectedLessonId)}
                    />
                    <PrevNextLessonButtons
                        lessonsData={lessonsData}
                        currentLessonIndex={currentLessonIndex}
                        displayLesson={displayLesson}
                        nextLesson={nextLesson}
                        previousLesson={previousLesson}
                    />
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default StudentPopup;
