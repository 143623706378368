import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
//import '../../css/componentStyles.css';
import './bannerNoImg.css';

//FontAwesome Imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookOpen, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

library.add( faBook, faBookOpen, faUserGraduate );


export default function BannerNoImg() {

    const navigate = useNavigate();

    const navigateSignup = () => {
        navigate('/Signup');
    }

    return (
        <div className="bannerNoImgContainer">
                <div className="topText">
                    <p></p>
                </div>
                <div className="bottomText">
                    <p>Using <b>AI technology</b> and our <b>educator approved curriculum</b>, your students can start learning with the science of reading today!</p>
                    <button className="bannerNoImgBtn" onClick={navigateSignup}>Sign Up!</button>
                </div>
            <div className="icons">
                {/* Row 1 */}
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "-50px", left: "750px", color: "#AA4747"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "20px", left: "820px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "90px", left: "890px", color: "#F3C03B"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "160px", left: "960px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "230px", left: "1030px", color: "#AA4747"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "300px", left: "1100px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "370px", left: "1170px", color: "#F3C03B"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "440px", left: "1240px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "510px", left: "1310px", color: '#AA4747'}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "580px", left: "1380px"}} className="iconBookClosed"/>
                {/* Row 2 */}
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "-55px", left: "900px", color: "#AA4747"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "15px", left: "970px"}}className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "85px", left: "1040px", color: "#F3C03B"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "155px", left: "1110px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "225px", left: "1180px", color: "#AA4747"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "295px", left: "1250px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "365px", left: "1320px", color: "#F3C03B"}} className="iconBookClosed"/> 
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "435px", left: "1390px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "505px", left: "1460px", color: '#AA4747'}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "575px", left: "1530px"}} className="iconBookClosed"/>
                {/* Row 3 */}
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "-60px", left: "1050px", color: "#AA4747"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "10px", left: "1120px"}} className="iconBookClosed"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "80px", left: "1190px", color: "#F3C03B"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "150px", left: "1260px"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "220px", left: "1330px", color: "#AA4747"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "290px", left: "1400px"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "360px", left: "1470px", color: "#F3C03B"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "430px", left: "1540px"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "500px", left: "1610px", color: "#AA4747"}} className="iconBookClosed mobileHide"/>
                <FontAwesomeIcon icon="fa-solid fa-book" style={{ top: "570px", left: "1680px"}} className="iconBookClosed mobileHide"/>
            </div>
        </div>
    )
}