import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import ClassRoster from "../components/teacher/classRoster";
import '../css/classroom.css';

const Classroom = () => {
    
    const { firstName, lastName, teacherDocumentID, classCode } = useParams();

    return (
        <div className='' style={{minHeight: '90vh', backgroundColor: 'beige', display: 'flex'}}>
            <Sidebar
                firstName={firstName}
                lastName={lastName}
                teacherDocumentID={teacherDocumentID}
                classCode={classCode}
            />
            <div className="rosterPage">
                <ClassRoster
                    teacherDocumentID={teacherDocumentID}
                />
            </div>
        </div>
    );
};

export default Classroom;
