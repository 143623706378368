import { useState, useEffect } from "react";
import OpenAI from 'openai';
import { firestore, storage } from "../../firebase";
import { doc, getDoc, addDoc, getDocs, collection, setDoc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useLocation } from 'react-router-dom';
import SelectSetting from '../demo/selectSetting';
import CharacterSelect from "../demo/selectCharacter";
import Noise from '../../images/noise.png';
import './lessonSession.css';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong, faMicrophone, faShare, faReply } from '@fortawesome/free-solid-svg-icons';
import Stop from '../../images/Stopsign.png';

import { useNavigate } from "react-router-dom";

library.add( faArrowRightLong, faArrowLeftLong, faMicrophone, faShare, faReply );


export default function LessonSession() {

  const location = useLocation();
  const teacherDocumentID = location.state.teacherID;
  const studentDocumentID = location.state.studentID;
  const studentCurrentObjectiveNumber = location.state.studentCurrentObjectiveNumber;
  const currentObjectiveEnglishTranslations = location.state.currentObjectiveEnglishTranslations;
  const currentObjectivePhonemicTranslations = location.state.currentObjectivePhonemicTranslations;
  const allEnglishTranslations = location.state.allEnglishTranslations;
  const allPhonemicTranslations = location.state.allPhonemicTranslations;
  let passedLessons = location.state.passedLessons;
  let totalLessons = location.state.totalLessons;

  // Session Variables
  const [selectedObjectiveNumber, setSelectedObjectiveNumber] = useState('');
  const [objectiveLoading, setObjectiveLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [openAIOutput, setOpenaiOutput] = useState('');

  // Audio transcript variables
  const [transcriptToSubmit, setTranscriptToSubmit] = useState("");
  const [storyContentToSubmit, setStoryContentToSubmit] = useState("");

  // Scoring variables
  let accuracy = 0;

  // Audio Recorder variables
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [contentLoaded, setContentLoaded] = useState(false);

  const navigate = useNavigate();

  // Image Gen variables
  const [prompt, setPrompt] = useState("");
  const [result, setResult] = useState("");
  const [imgLoading, setImgLoading] = useState('');

  //setting variables
  const [selectedSetting, setSelectedSetting] = useState('');

  //character variables
  const [selectedCharacter, setSelectedCharacter] = useState('');

  function toStudentDash (){
    navigate('/studentDash', { 
      state: { 
      studentID : studentDocumentID, 
      teacherID : teacherDocumentID, 
      studentCurrentObjectiveNumber : studentCurrentObjectiveNumber,
      passedLessons : passedLessons,
      currentObjectiveEnglishTranslations : currentObjectiveEnglishTranslations,
      currentObjectivePhonemicTranslations : currentObjectivePhonemicTranslations,
  }});
}


  // *******************************************
  // Audio Transcription Functions and Variables
  // *******************************************

const {
  transcript,
  listening,
  resetTranscript,
  browserSupportsSpeechRecognition
} = useSpeechRecognition();

const startListening = () => {
  SpeechRecognition.startListening({ continuous: true });
}

const stopListening = async (audioURL) => {
  SpeechRecognition.stopListening();
  compareTexts(audioURL);

  resetTranscript();
  toStudentDash(); // Navigate to the student dashboard   
}

const writeLesson = async (totalLessons, audioURL) => {
  const sentencesDocRef = await setDoc(doc(firestore, `users`, `${teacherDocumentID}`, `students`, `${studentDocumentID}`, 'objectives', `objective${studentCurrentObjectiveNumber}`, `lessons`, `lesson${totalLessons + 1}`), {
    storyContent: sentences, 
    transcript: transcriptToSubmit || transcript,
    score: accuracy,
    audio: audioURL,
    pass: null,
    isGraded: false
  })
}


// *************************************
// Compare text Functions and Variables
// *************************************

const compareTexts = async (audioURL) => {
  let passCondition = 90;
  let newString = sentences.join(' ').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ");

  const sentencesString = newString.toLowerCase().split(/\s+/).filter(Boolean);
  const transcriptString = transcript.toLowerCase().split(/\s+/).filter(Boolean);

  let matchingWords = 0;

  sentencesString.forEach(word => {
    const matchedIndex = transcriptString.findIndex(w => w === word);
    if (matchedIndex !== -1) {
      matchingWords++;
      transcriptString.splice(matchedIndex, 1);
    }
  });

  const totalWords = sentencesString.length;
  const similarity = (matchingWords / totalWords) * 100;

  accuracy = similarity.toFixed(2);

  try {
    const checkForDocRef = doc(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `objective${studentCurrentObjectiveNumber}`);
    let docSnap = await getDoc(checkForDocRef);
    if(docSnap.exists()) { // Corrected this line
      const objectiveDocRec = await updateDoc(checkForDocRef, {
        totalLessons: parseInt(totalLessons) + 1 // Assuming totalLessons is a number, corrected the increment
      });
      writeLesson(totalLessons, audioURL);
    } else {
      const objectiveDocRec = await setDoc(checkForDocRef, {
        passedLessons: parseInt(passedLessons),
        objectiveNumber: studentCurrentObjectiveNumber,
        totalLessons: parseInt(totalLessons) + 1
      });
      writeLesson(totalLessons, audioURL);
    }
  } catch (error) {
    console.log(error + ' catch error fired');
  }
  
}

  // *************************************
  // Audio Recorder Functions and Variables
  // *************************************
  
  const startRecording = () => {
    setRecording(true);
  };

  const stopRecordingAndUpload = async () => {
    setRecording(false);
    const audioBlob = await recorderControls.stopRecording();
    return audioBlob;
  };

  useEffect(() => {
    if (contentLoaded) {
      startRecording();
    }
  }, [contentLoaded]);

  const uploadAudio = async (blob) => {
    const storageRef = ref(storage, `audio/recordedLessons/${studentDocumentID}/objective${studentCurrentObjectiveNumber}/lesson${totalLessons + 1}.mp3`);
    const uploadTask = uploadBytesResumable(storageRef, blob);
    try {
      await uploadTask;
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      console.log("File available at", downloadURL);
      setAudioURL(downloadURL);
      stopListening(downloadURL); // Stop speech recognition
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  useEffect(() => {
    if (recording) {
      recorderControls.startRecording();
    } else {
      if (recorderControls.isRecording) {
        recorderControls.stopRecording();
      }
    }
  }, [recording]);

// Define handleFinish function
const handleFinish = () => {
  setRecording(false);
  const audioBlob = recorderControls.stopRecording();
  if (audioBlob) {
    uploadAudio(audioBlob); 
  } else {
    console.error("stopRecording() did not return a valid audioBlob.");
  }
};



  // ****************************************
  // Story Generation Variables and Functions
  // ****************************************

  const handleStorySubmit = async () => {
    setTextLoading(true);

    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

    const promptTemplate = `
      Please take this user input for a topic of a story: ${selectedCharacter} and ${selectedSetting.length > 0 ? selectedSetting : 'do not use a setting in the story'}
      and write a short, simple story about this character. 
      This story is for kindergarten aged children and should only contain the phonemic structures provided, conjugated in any way. 
      Please break up the story into sentences of 7-10 words, and make the whole story about 30 words. 
      Most importantly, the story must comprise of only words constructible by the following phonemic structures: 
      ${allPhonemicTranslations.length > 0 ? allPhonemicTranslations.join(", ") : 'fetch phonemicTranslations before entering'}. 
      The structures you were given use the phonemic symbols with them, so a symbol '/k/' could be used for the English sound "ck" as well.
      With those sounds, please use them in English words containing these literal English translations:
      ${allEnglishTranslations.length > 0 ? allEnglishTranslations.join(", ") : 'fetch englishTranslations before entering'}.
      Make sure to check grammar first.
      This is very important, since no word can be in the story that is not able to be conjugated using science of reading techniques 
      that combine phonemes and sight words to create words, or else the student that this lesson is designed for would not be able to read the word. 
      You are restricted to only use words that you can make with the provided phonemes. 
      Do not create sentences with question marks, exclamation marks, apostrophes, commas, or quotation marks.
      Do not include characters: "!", ",", "'", "?", """.
      Only generate the story, do not include any additional notes or anything not in the story.
      To ensure that only these phonemes and sight words are used in creation of the story,
      before outputting the story, make sure that each word is conjugable by the provided phonemicStructures.
    `;

    const stream = await openai.beta.chat.completions.stream({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: promptTemplate }],
      stream: true,
      max_tokens: 700
    });

    let generatedStory = '';

    stream.on('content', (delta, snapshot) => {
      setTextLoading(false);
      generatedStory += delta;
      setOpenaiOutput(generatedStory.trim());
      const storySentences = generatedStory.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.trim() + '.');
      setSentences([...storySentences]);
    });
  };

  const handleNextSentence = () => {
    document.getElementById('page').style.transform = 'rotateY(180deg)';
    setCurrentSentenceIndex((prevIndex) => Math.min(prevIndex + 1, sentences.length - 1));
  };

  const handlePrevSentence = () => {
    document.getElementById('page').style.transform = 'rotateY(0deg)';
    setCurrentSentenceIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };



  // ****************************************
  // Image Generation Variables and Functions
  // ****************************************

  // GPT Account API Key
  const OPENAI_API_KEY = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

  const GenerateImage = async ( x ) => {
    setImgLoading(true);
    const res = await OPENAI_API_KEY.images.generate({
      model: "dall-e-3",
      prompt: x,
      n: 1,
      size: "1024x1024"
    });
    setImgLoading(false);
    setResult(res.data[0].url);
  };

  useEffect(() => {
    if (selectedCharacter && (selectedSetting || selectedSetting === "")) {
      const newPrompt = "Illustrate a scene or characters for a children’s book, using this character: " + selectedCharacter + (selectedSetting ? " and this setting: " + selectedSetting : "" + ".");
      setPrompt(newPrompt);
    } 
  }, [selectedCharacter, selectedSetting, prompt]);
  


  return (
    <div className="studentLessonContainer">
      {!!!allEnglishTranslations && allPhonemicTranslations ? (
        <div className='startCreatingBtn'>
          Loading Phonemic Structures
        </div>
      ) : (
        <>
          {!!!selectedCharacter ? (
            <div className="lessonSelectCharacter">
              <h3 className='lessonSelectHeader'>Select a Character!</h3>
              <CharacterSelect onSelectCharacter={setSelectedCharacter} />
            </div>
          ) : (
            <>
              {!!!selectedSetting && studentCurrentObjectiveNumber >= 20? (
                <div className="lessonSelectHeader">
                  <h3 className='lessonSelectHeader'>Select a Setting!</h3>
                  <SelectSetting onSelectSetting={setSelectedSetting} />
                </div>
              ) : (
                <>
                  <div id="buttonContainer">
                    <button className='generateBtn' onClick={(e) => {handleStorySubmit(); GenerateImage(prompt); e.target.style.display = 'none'}}>Start Reading!</button>
                  </div>
                  {imgLoading ? (
                    <>
                      <h3 style={{fontSize: '30px', color: '#292F36'}}>Writing your story!</h3>
                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </>
                  ) : (
                    <>
                    {!result ? (
                      <></>
                    ) : (
                      <>
                      <div className="bookCover" style={{backgroundImage: `url(${Noise}`, backgroundSize: 'cover'}}>
                      <div className="bookPages Outer">
                      <div className="bookPages Inner">
                      <div onLoad={() => {startListening(); setContentLoaded(true)}} className='generatedContentSection'>
                        <div className="generatedImgArea">
                            {recording && (
                              <div className="recordingIndicator">
                                  <FontAwesomeIcon icon="microphone" size="3x" color="red" />
                              </div>
                            )}
                            <img src={result} />
                            <button onClick={handlePrevSentence} className="arrowBtn leftArrow" disabled={currentSentenceIndex === 0}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-reply" /></button>
                        </div>
                             <div className="generatedStoryArea" id="page">
                            <p>{sentences[currentSentenceIndex]}</p>
                            <div>
                                <>
                                  {currentSentenceIndex == sentences.length - 1 ? (
                                      <>
                                        <button className='finishBtn' onClick={()=> {handleFinish()}} >
                                              <img src={Stop} className='stopSign' alt='Stop Sign Icon'/>
                                        </button>
                                        <AudioRecorder
                                            className = 'audioRecorderComponent'
                                            style = {{display: 'none'}}
                                            recorderControls={recorderControls}
                                            showVisualizer={true}
                                            onRecordingComplete={(blob) => {
                                              recorderControls.stopRecording();
                                              uploadAudio(blob);
                                            }}
                                          />
                                      </>
                                  ) : (
                                      <>
                                        <button onClick={handleNextSentence} className='arrowBtn rightArrow' disabled={currentSentenceIndex === sentences.length - 1}><FontAwesomeIcon className='storyArrows' icon="fa-solid fa-share" /></button>
                                      </>
                                  )}
                                </>
                            </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                    </> 
                    )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}